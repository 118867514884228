import $ from 'jquery';
import openModal from '../../utils/openModal';
import Sortable from 'sortablejs';
import FlowAsync from '../../utils/flowAsync.js';

export default class {
  constructor($target) {
    this.$target = $target;
    this.bind();
  }

  bind() {
    /**
     * 各フローモーダル表示
     * @type {[type]}
     */
    this.$target.on('click', '.js-openRemodal', ((e) => {
      const $target = $(e.currentTarget);
      if($target.attr('data-modalName') === 'deleteModal') {
        const targetVal = $target.attr('data-val');
        const targetPage = $target.attr('data-page');
        const csrf = $('[name="csrf-token"]').attr('content');
        $('.js-delete_form').append(`<input name="_token" type="hidden" value="${csrf}" class="js-src">`)
        $('.js-delete_form').attr('action', `/company/${targetPage}/delete/${targetVal}`);
        openModal($target.attr('data-modalName'));
      } else {
        openModal($target.attr('data-modalName'));
        Sortable.create($('.js-sort_modal')[0]);
      }
    }));

    /**
     * フロー登録時の項目削除
     */
    this.$target.on('click', '.js-delete', ((e) => {
      const $target = $(e.currentTarget);
      const last = $target.parent().siblings('.js-submissions').find('div:last');
      if(~last.find('input').attr('name').indexOf('-')) {
        const lastId = last.find('input').attr('name').split('-');
        $target.parent().append(
          `
            <input type="hidden" name="delete_id[]" value="${lastId[lastId.length - 1]}">
          `
        );
      }
      $(last).remove();
      if($('.js-submissions').find('div').length === 0) {
        $target.prop('disabled', true);
      }
    }));

    /**
     * フロー登録時の項目追加
     */
    this.$target.on('click', '.js-add', ((e) => {
      const $target = $(e.currentTarget);
      const elementCount = $target.parent().siblings('.js-submissions').find('div');
      const pattern = "pattern='\\S+'";
      $target.prev().prop('disabled', false);
      $('.js-submissions').append(
        `
          <div>
            <label>提出物or確認事項${elementCount.length + 1}</label>
            <input name="flow_chile_category_name[]" type="text" class="form-control" placeholder="提出物or確認事項" required ${pattern}>
          </div>
        `
      );
    }));

    this.$target.on('click', '#sample2check', ((e) => {
      let $target = $(e.currentTarget);
      new FlowAsync;
    }));
    
  }
}
