import $ from 'jquery';
import { request, apiUrl, params } from '../utils';

const baseUrl = 'api/calendar';


export default class CalendarModel {
  calendarindex = async (params) => {
    try {
      const param = $.param(params);
      const res = await request.GET(apiUrl.fetchUrl(`${baseUrl}`, `list?${param}`));

      return res;
    } catch (e) {
      throw new Error(e);
    }
  }

  calendarshow = async ($id) => {
    try {
      const res = await request.GET(apiUrl.fetchUrl(`${baseUrl}`, `show/${$id}`));
      return res;
    } catch (e) {
      throw new Error(e);
    }
  }

}
