import $ from 'jquery';

export default ($target, file) => {
  const btnName = $target.attr('name');
  const reader = new FileReader();
  const $targetPreview = $(`.preview${btnName}`);

  if (file.type.indexOf('image') < 0) {
    return false;
  }

  $targetPreview.children('.preview').remove();
  $targetPreview.children('.noImage').hide();

  reader.onload = ((file) => {
    return (e) => {
      $targetPreview.append($('<img>').attr({
        src: e.target.result,
        width: '85px',
        class: 'preview',
        title: file.name,
      }));
    };
  })(file);
  reader.readAsDataURL(file);
};
