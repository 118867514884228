import $ from 'jquery';

export default class {
  constructor($target) {
    this.$target = $target;
    this.bind();
  }

  bind() {

    /**
     * フロー入れ替え
     * @type {[type]}
     */
    this.$target.on('drop', '.js-sort_list', ((e) => {
      const sortList = $('.js-sort_list');
      $.each(sortList, ((i, x) => {
        $(x).find('.js-sort_hidden').val(i + 1);
      }));
    }));
  }
}
