import $ from 'jquery';
import request from 'superagent';
import cookie from './cookie';
// import timeoutRedirectLogin from './timeoutRedirectLogin';

export default class {
  /**
   * GET用のメソッド
   * @param {string} url [GETのURL]
   * @param {string} token [jwtのtokenSSRで使用する]
   * @return {object} [GETの返り値]
   */
  static GET(url) {
    const token = cookie.read('token');
    return new Promise((resolve, reject) => {
      request.get(url)
      .set('Authorization', `Bearer ${token}`)
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('X-Requested-With', 'XMLHttpRequest')
      .end((err, res) => {
        if (err) {
          timeoutRedirectLogin(err);
          reject(err);
        } else {
          resolve(res.body);
        }
      });
    });
  }

  /**
   * JSONのPOST用のメソッド
   * @param {string} url    [POSTのURL]
   * @param {object} params [POSTする配列]
   * @return {object} POSTの返り値
   */
  static POST(url, params) {
    const token = cookie.read('token');
    return new Promise((resolve, reject) => {
      request.post(url)
      .set('Authorization', `Bearer ${token}`)
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('X-Requested-With', 'XMLHttpRequest')
      .send(params)
      .end((err, res) => {
        console.log(err);
        if (err) {
          timeoutRedirectLogin(err);
          reject(err);
        } else {
          resolve(res.body);
        }
      //   // } else {
      //   console.log(res);
      //     resolve(res.body);
      //   // }
      });
    });
  }

  /**
   * JSONのPUT用のメソッド
   * @param {string} url    [PUTのURL]
   * @param {object} params [PUTする配列]
   * @return {object} PUTの返り値
   */
  static PUT(url, params) {
    const token = cookie.read('token');
    return new Promise((resolve, reject) => {
      request.put(url)
      .set('Authorization', `Bearer ${token}`)
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('X-Requested-With', 'XMLHttpRequest')
      .send(params)
      .end((err, res) => {
        // if (err) {
        //   timeoutRedirectLogin(err);
        //   reject(err);
        // } else {
        //   resolve(res.body);
        // }
      });
    });
  }

  /**
   * [ファイルアップロード用のメソッド]
   * @param {string} url   [POST先のURL（pathのみ）]
   * @param {object} file [POSTするFILEオブジェクト]
   * @return {object} アップロードした画像のパス
   */
  static UPLOAD(url, file) {
    const token = cookie.read('token');
    return new Promise((resolve, reject) => {
      request.post(url)
      .set('Authorization', `Bearer ${token}`)
      .set('X-Requested-With', 'XMLHttpRequest')
      .attach('file', file)
      .end((err, res) => {
        // if (err) {
        //   timeoutRedirectLogin(err);
        //   reject(err);
        //   throw new Error(err);
        // } else {
        //   resolve(res.body);
        // }
      });
    });
  }
}
