import config from '../config';

export default class {
  /**
   * [baseUrlとparamUrlをURLとして結合し、バージョン含めて返す]
   * @param  {string} baseUrl  [APIのベースとなるURL]
   * @param  {string} paramUrl [追加のURL]
   * @param  {string} version  [APIのバージョン]
   * @return {string}          [APIのURL]
   */
  static fetchUrl(baseUrl, paramUrl, version) {
    baseUrl = paramUrl ? `${baseUrl}/${paramUrl}` : baseUrl;
    // console.log(baseUrl, 'ここ', version);
    return this.version(version, baseUrl);
  }

  /**
   * [config指定のapiURLをもとにバージョン・パスを含めて返す]
   * @param  {string} version [APIのバージョン]
   * @param  {string} path    [APIのパス]
   * @return {string}         [APIのURL]
   */
  static version(version, path) {
    // const versionPath = `${version}/${path}`;
    const versionPath = `${path}`;
    return config.apiUrl + versionPath;
  }
}
