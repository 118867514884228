import $ from 'jquery';
import openModal from '../../utils/openModal';

export default class {
  constructor($target) {
    this.$target = $target;
    this.bind();
  }

  bind() {
    $(document).ready((() => {
      const sidebarlink = $('.sidebar .linkWrap a');
      const currentUrl = location.pathname;
      const currentUrlSplit = currentUrl.split('/');
      let currentUrlCount = '';
      if(currentUrlSplit.length === 5) {
        currentUrlCount = currentUrlSplit.length - 3;
      } else if(currentUrlSplit.length > 3) {
        currentUrlCount = currentUrlSplit.length - 2;
      } else if(currentUrlSplit.length < 3 && currentUrlSplit.length > 4) {
        currentUrlCount = currentUrlSplit.length - 1;
      } else if(currentUrlSplit.length > 2) {
        currentUrlCount = currentUrlSplit.length - 1;
      } else {
        currentUrlCount = currentUrlSplit.length - 1;
      }
      $.each(sidebarlink, ((i, x) => {
        if($(x).attr('href').indexOf(currentUrlSplit[currentUrlCount]) > -1) {
          $(x).parent().addClass('active');
        } else {
          $(x).parent().removeClass('active');
        }
      }));
    }));

    this.$target.on('click', '.js-openRemodal', ((e) => {
      const $target = $(e.currentTarget);
      openModal($target.attr('data-modalName'));
    }));
  }
}
