import apiUrl from '../config';

export default class FlowAsync{
  constructor(){
    this.bind();
  }

  bind(){
    asyncPrepare();
  }
}

/**
 * [asyncPrepare 非同期準備関数]
 * [クラス付与でロード画面出力]
 * [チェックボックスのcheckedでON関数かOFF関数か判別]
 *
 * @return  {[void]}  [return void]
 */
function asyncPrepare(){
  // ロード画面
  document.getElementById('js-load_wrap').classList.add('load_wrap');
  document.getElementById('js-load_box').classList.add('load_box');
  document.getElementById('js-loader').classList.add('loader');

  // ON OFF 判断
  if(document.getElementById('sample2check').checked === true){
    flowDocumentOn();
  }else if(document.getElementById('sample2check').checked === false){
    flowDocumentOff()
  }
}

/**
 * [async flowDocumentOff asyncExecute関数実行関数]
 * [実行後、描画されているエレメント削除、取得した情報を描画]
 * [描画後、クラス削除（ロード画面削除）]
 *
 * @return  {[void]}  [return void]
 */
async function flowDocumentOff(){
  const res = await asyncExecute(apiUrl.apiUrl + 'api/company/flow/off');

  // 非同期通信後の処理を記述

  let child = document.getElementById('js-flow_item').childNodes;
  for(let i = 0; i < child.length;){
    child[0].remove();
  }

  addFlowElement(res.data);

  document.getElementById('label_document').style.color='#788687';

  document.getElementById('js-load_wrap').classList.remove('load_wrap');
  document.getElementById('js-load_box').classList.remove('load_box');
  document.getElementById('js-loader').classList.remove('loader');
}

/**
 * [async flowDocumentOn asyncExecute関数実行関数]
 * [実行後、描画されているエレメント削除、取得した情報を描画]
 * [描画後、クラス削除（ロード画面削除）]
 *
 * @return  {[void]}  [return void]
 */
async function flowDocumentOn(){
  const res = await asyncExecute(apiUrl.apiUrl + 'api/company/flow/on');
  
  // 非同期通信後の処理を記述

  let child = document.getElementById('js-flow_item').childNodes;
  for(let i = 0; i < child.length;){
    child[0].remove();
  }

  addFlowElement(res.data);

  document.getElementById('label_document').style.color='#0CC099';

  document.getElementById('js-load_wrap').classList.remove('load_wrap');
  document.getElementById('js-load_box').classList.remove('load_box');
  document.getElementById('js-loader').classList.remove('loader');
}

/**
 * [asyncExecute API情報取得関数]
 *
 * @param   {[String]}  APIurl  [APIurl 遷移先URL]
 *
 * @return  {[Array]}          [return データベース情報]
 */
function asyncExecute(APIurl){
  const search_url = location.search.slice(6, location.search.length);
  const url = APIurl;
  const data = JSON.stringify({
    'page': search_url,
    'company_id': document.getElementById('js-company_id').value
  });
  return fetch(url, {
    method: 'POST',
    body: data,
    headers:{
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
  .then(response => response.json());
}

/**
 * [addFlowElement 取得したデータを描画する関数]
 *
 * @param   {[Array]}  data  [data APIから取得した情報配列]
 *
 * @return  {[void]}        [return void]
 */
function addFlowElement(data){
  Object.keys(data).forEach(function(key){
    const sort_id = data[key].sort_id;
    const flow_name = data[key].flow_name;
    const flow_id = data[key].flow_id;
    let box = 
      `<tr>
        <td>${sort_id}</td>
        <td>${flow_name}</td>
        <td>
          <div class="btn btn-info linkWrap">
            <a href="/company/flow/edit/${flow_id}"></a>
            <i class="fas fa-edit"></i>
          </div>
        </td>
        <td>
          <div class="btn btn-danger linkWrap">
            <a href="#" class="js-openRemodal" data-modalName="deleteModal" data-page="flow" data-val="${flow_id}"></a>
            <i class="fas fa-trash-alt"></i>
          </div>
        </td>
      </tr>`
    ;
    document.getElementById('js-flow_item').insertAdjacentHTML('beforeend', box);
  });
}