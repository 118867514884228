import $ from 'jquery';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import 'remodal';
import moment from 'moment';
import calendarDom from '../../utils/calendarCommon';
import Calendars from '../../model/CalendarModel';
import cytoscape from 'cytoscape';
import cyqtip from 'cytoscape-qtip';

import load from '../../utils/load';

const calendarModel = new Calendars();
cytoscape.use(cyqtip);

export default class {
	constructor($target) {
		this.$target = $target;
		this.bind();
	}

	bind() {
		// modalオプション
		const options = {
			// 背景クリック不可
			closeOnOutsideClick: false,
			hashTracking: false,
		};

		// momentjs曜日日本語化
		moment.locale("ja", {
			weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
			weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
		});

		$('document').ready((() => {
			const calendarEl = document.getElementById('js-calendar_element');
			let calendar = new Calendar(calendarEl, {
				handleWindowResize: true,
				windowResizeDelay: 200,
				locale: 'ja',
				timeZone: 'JA',
				plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
				initialView: 'timeGridWeek',
				headerToolbar: {
					left: 'prev,next',
					center: 'title',
					right: ''
				},
				allDaySlot: false,
				events: function (fetchInfo, successCallback, failureCallback) {
					let param = {};
					let events2 = {};
					let events = [];
					let singleEventCompany = '';
					let singleEventUser = '';
					const now = moment(new Date()).format('YYYY-MM-DD HH:mm');
					const userId = $('.js-users').val();
					const calendarFlg = $('.js-calendar_flg').val();
					param.company_id = $('.js-company').val();
					(async () => {
						try {
							load.loadStart();
							const res = await calendarModel.calendarindex(param);
							load.loadEnd();
							$.each(res, ((i, x) => {
								singleEventCompany = calendar.getEventById('c' + x.id);
								if (singleEventCompany) {
									singleEventCompany.remove();
								}
								// 企業カレンダー描画
								$.each(x.fix_calendar_company_event, ((key, company_event) => {
									events.push({
										id: 'c' + company_event.id,
										title: '面接可能 ' + x.name,
										start: company_event.calendar_company_event_start_time,
										end: company_event.calendar_company_event_end_time,
										className: `js-event_cancel cancel_company js-hover js-event_show js-manager_id_${x.id}`,
										backgroundColor: x.color,
									});

									// ユーザーカレンダー描画
									$.each(company_event.user_event, (k, user_event) => {
										singleEventUser = calendar.getEventById('u' + user_event.id);
										if (singleEventUser) {
											singleEventUser.remove();
										}

										if (Number(calendarFlg) === 2) {
											events.push({
												id: 'u' + user_event.id,
												title: user_event.user.flow.flow_name + '\r\n' + x.name + '\r\n' + user_event.user.name,
												start: user_event.calendar_user_event_start_time,
												end: user_event.calendar_user_event_end_time,
												className: 'js-event_cancel cancel_user js-hover js-event_show',
											});
										} else {
											if (Number(userId) === Number(user_event.user.id)) {
												events.push({
													// ログインしているユーザー
													id: 'u' + user_event.id,
													title: user_event.user.flow.flow_name + '\r\n' + x.name + '\r\n' + user_event.user.name,
													start: user_event.calendar_user_event_start_time,
													end: user_event.calendar_user_event_end_time,
													className: 'js-event_cancel cancel_user js-hover js-event_show',
													backgroundColor: x.color,
													textColor: '#ffc107',
													borderColor: '#FF0000',
												});
											} else {
												// それ以外のユーザー
												events.push({
													id: 'u' + user_event.id,
													title: '予約不可',
													start: user_event.calendar_user_event_start_time,
													end: user_event.calendar_user_event_end_time,
													className: '',
													backgroundColor: '#708090',
													borderColor: x.color,
													textColor: x.color,
												});
											}
										}
									});
								}));
								successCallback(events);
							}));
						} catch (e) {
							console.log(e);
						};
					})();
				},
				eventMouseEnter: function (mouseEnterInfo) { },
				eventDidMount: function (info) {
					if (info.event.id.indexOf('u') > -1) {
						if (info.event.backgroundColor) {
							info.el.style.background = info.event.backgroundColor;
						} else {
							info.el.style.background = 'red';
						}
					}
				},
				eventClick: function (info) {
					const calender_id = info.event.id.replace('c', '');
					const now = moment(new Date()).format('YYYY-MM-DD HH:mm');
					const day = moment(info.event.startStr).format('YYYY/MM/DD');
					const start_time = info.event.startStr;
					const end_time = info.event.endStr;
					const week = moment(info.event.start).format('(ddd)');
					const today = moment().format('YYYY/MM/DD');
					let manager_id = '';
					if (info.event.classNames[4]) {
						manager_id = info.event.classNames[4].replace('js-manager_id_', '');
					}
					let userEventDay = [];
					let userEventStart_time = [];
					let userEventEnd_time = [];
					$('.js-cancel_id').val(info.event.id);
					if (location.pathname.indexOf('company') > -1) {

						// companyの処理
						//自分と同じIDのカレンダー以外キャンセルできないようにする
						if ($(info.el).hasClass('cancel_company') && $(info.el).hasClass(`js-manager_id_${me.id}`)) {
							$('.js-reservation').find('div').remove();
							$('.js-reservation').append(calendarDom.cancelCompanyCalendar(day, start_time, end_time, week));
							$('[data-remodal-id="js-event_cancel"]').remodal(options).open();
						} else {
							// companyが他のcompanyの上に予定を入れるときの処理
							$('.js-reservation').find('div').remove();
							$('.js-reservation').append(calendarDom.companyOnCompany(day, start_time, end_time, week, now));
							$('[data-remodal-id="fc-timegrid-slot-lane"]').remodal(options).open();

							// start_optionの値が変更されたらend_optionとuse_optionを変更
							$('.start_option').on('change', () => {
								let start_time_new = moment(start_time).format(`YYYY-MM-DD ${$('.start_option').val()}`);
								let end_time_new = moment(start_time_new).add(30, 'm').format(`YYYY-MM-DD HH:mm`);
								$('.end_option').find('option').remove();
								$('.use_option').find('option').remove();
								const option = calendarDom.myFunc(start_time_new, end_time_new, now);
								$('.end_option').append(option['end_option']);
								$('.use_option').append(option['use_option']);
							});
							$('.end_option').on('change', () => {
								let start_time_new = moment(start_time).format(`YYYY-MM-DD ${$('.start_option').val()}`);
								let end_time_new = moment(end_time).format(`YYYY-MM-DD ${$('.end_option').val()}`);
								$('.use_option').find('option').remove();
								const option = calendarDom.myFunc(start_time_new, end_time_new, now);
								$('.use_option').append(option['use_option']);
							});
						}
					} else {
						// userの処理
						if ($(info.el).hasClass('cancel_user')) {
							// user モーダル展開
							$('.js-reservation').find('div').remove();
							$('.js-reservation').append(calendarDom.cancelCompanyCalendar(day, start_time, end_time, week));
							$('[data-remodal-id="js-event_cancel"]').remodal(options).open();
						} else {
							// userがcompanyの予定クリック
							if (today <= day) {
								(async () => {
									try {
										load.loadStart();
										const userId = $('.js-valiuid').attr('data-id');
										let check = '';
										const res = await calendarModel.calendarshow(info.event.id);
										load.loadEnd();

										// res.user_eventが存在したら予定をいれさせない
										if (!res.user_event) {
											check = 1;
										} else {
											$.each(res.user_event, ((i, x) => {
												// 予定を入れる時の処理
												// 予定が入っているものはoptionから削除するため削除対象を作成
												// conpanyのidとuserのmanager_idが一致していればtrue

												if (
													Number(manager_id) === Number(x.user.child_company.id) &&
													day === x.calendar_user_event_day
													// now <= x.calendar_user_event_day
												) {
													userEventDay.push(x.calendar_user_event_day);
													userEventStart_time.push(x.calendar_user_event_start_time);
													userEventEnd_time.push(x.calendar_user_event_end_time);
													if (x.user_id === Number(userId)) {
														check = 1;
													}
												}

												// company側を押した時の処理（押した時日時が被ってたらcheck = 1）
												if (
													res.calendar_company_event_day === x.calendar_user_event_day &&
													res.calendar_company_event_start_time === x.calendar_user_event_start_time &&
													res.calendar_company_event_end_time === x.calendar_user_event_end_time ||
													res.length === 0
												) {
													check = 1;
												}
											}));
										}

										if (check) {
											// 既に予定いれてる処理
											$('[data-remodal-id="calenderDuplicateModal"]').remodal(options).open();
										} else {
											// user 予定入れます
											const user_event = res.user_event;
											const time = res.calendar_company_event_time;
											$('.js-reservation').find('div').remove();
											$('.js-reservation').append(calendarDom.userTimeBox(
												day,
												start_time,
												end_time,
												week,
												userEventDay,
												userEventStart_time,
												userEventEnd_time,
												time,
												calender_id,
												user_event,
												manager_id,
											));
											$('[data-remodal-id="fc-timegrid-slot-lane"]').remodal(options).open();

											$('.js-start_time_modal').on('change', (e) => {
												const $target = $(e.currentTarget);
												const $targetTime = $target.find('option:selected').attr('data-val');
												const $targetTimeAdd = moment($targetTime).add(time / 3600000, 'hours').format('HH:mm');
												$('.js-end_time_modal').text($targetTimeAdd);
												$('.js-calendar_user_event_end_time').val($targetTimeAdd);
											})

										}
									} catch (e) {
										console.log(e);
									};
								})();
							} else {
								$('[data-remodal-id="calendarPastErrorModal"]').remodal(options).open();
							}
						}
					}
					if (location.pathname.indexOf('company') > -1) {
						if (!$(info.el).hasClass('cancel_company')) {
							const title = info.event._def.title.split('\r\n');
							$('.js-reservation').find('div').remove();
							$('.js-reservation').append(calendarDom.showCompanyCalendar(day, start_time, end_time, week, title));
							$('[data-remodal-id="js-event_show"]').remodal(options).open();
						}
					}
				},
				dateClick: function (info) {
					// dateをクリック
					$('.js-reservation').find('div').remove();
					let title = '';
					const now = moment(new Date()).format('YYYY/MM/DD HH:mm');
					const day_time = moment(info.dateStr).format('YYYY/MM/DD HH:mm');
					const day = moment(info.dateStr).format('YYYY/MM/DD');
					const start_time = moment(info.dateStr).format('HH:mm');
					const end_time = moment(info.dateStr).add(1, 'h').format('HH:mm');
					const week = moment(info.dateStr).format('(ddd)');
					const minutes = moment().minutes();
					const today = moment().format('YYYY/MM/DD');
					const all = info.dateStr;
					if (location.pathname.indexOf('company') > -1) {
						// company 予約入れる
						if (now <= day_time) {
							$('.js-reservation').append(calendarDom.companyCalendar(day, start_time, end_time, week, all));
							$('[data-remodal-id="fc-timegrid-slot-lane"]').remodal(options).open();

							let event_end_time = document.getElementsByName('calendar_company_event_end_time')[0];
							event_end_time.addEventListener('change', () => {
								event_end_time.value.split(':');
								start_time.split(':');
								let hour = event_end_time.value.split(':')[0] - start_time.split(':')[0];
								let minutes = Math.abs(event_end_time.value.split(':')[1] - start_time.split(':')[1]);
								let time = hour * 60 + minutes;
								$('#js-calendar_company_event_time').find('option').remove();
								for (let i = 30; i <= time; i += 30) {
									let option = document.createElement('option');
									option.append((i / 60) + '時間');
									option.value = i * 60000;
									document.getElementById('js-calendar_company_event_time').append(option);
								}
							})

						} else {
							$('[data-remodal-id="calendarPastErrorModal"]').remodal(options).open();
						}
					} else {
						$('.js-reservation').append(calendarDom.userCalendar(day, start_time, end_time, week));
						$('[data-remodal-id="calenderErrorModal"]').remodal(options).open();
					}
				},
			});

			calendar.render();
		}));
	}
}
