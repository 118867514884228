import $ from 'jquery';
import ifExists from './utils/ifExists';

require('./bootstrap');

import UserCsvCommon from './components/UserCsvCommon';
import FlowCommon from './components/FlowCommon';
import FlowModalCommon from './components/FlowModalCommon';
import QuestionCommon from './components/QuestionCommon';
import CalendarCommon from './components/CalendarCommon';
import CalendarModalCommon from './components/CalendarModalCommon';
import EntryCommon from './components/EntryCommon';
import SidebarCommon from './components/SidebarCommon';
import CompanyCommon from './components/CompanyCommon';
import ModalCommon from './components/ModalCommon';
import DeleteCommons from './components/DeleteCommons';
import AnalysisCommon from './components/AnalysisCommon';
import LineApiCommon from './components/LineApiCommon';
import AdminCompanyCommon from './components/AdminCompanyCommon';
import LoginCommon from './components/LoginCommon';
import judgementModalCommon from './components/judgementModalCommon';
import SortCommon from './components/SortCommon';
import EntryIndexCommon from './components/EntryIndexCommon';
import TaskCompanyCommon from './components/TaskCompanyCommon';
import HamburgurMenu from './components/HamburgurMenu';

const userCsv = new ifExists('.js-user_csv');
const flow = new ifExists('.js-flow');
const flowModal = new ifExists('.js-flowModal');
const question = new ifExists('.js-question');
const calendar = new ifExists('.js-calendar');
const calendarModal = new ifExists('.js-calendar_modal');
const entry = new ifExists('.js-entry');
const sidebar = new ifExists('.js-sidebar');
const company = new ifExists('.js-company');
const deleteModal = new ifExists('.js-deleteModal');
const deletes = new ifExists('.js-deleteCommon');
const analysis = new ifExists('.js-analysis');
const lineApi = new ifExists('.js-lineapi');
const adminCompany = new ifExists('.js-admin_company');
const login = new ifExists('.js-login');
const judgementModal = new ifExists('.js-judgementModal');
const sorts = new ifExists('.js-sorts');
const entryIndex = new ifExists('.js-entry_index');
const taskCompany = new ifExists('.js-task_company');
const commonModal = new ifExists('.js-commonModal');
const hamburgurMenu = new ifExists('.js-hamburgur-menu');

userCsv.exists(elements => {
    new UserCsvCommon($(elements));
});
flow.exists(elements => {
    new FlowCommon($(elements));
});
flowModal.exists(elements => {
    new FlowModalCommon($(elements));
});
question.exists(elements => {
    new QuestionCommon($(elements));
});
calendar.exists(elements => {
    new CalendarCommon($(elements));
});
calendarModal.exists(elements => {
    new CalendarModalCommon($(elements));
});
entry.exists(elements => {
    new EntryCommon($(elements));
});
sidebar.exists(elements => {
    new SidebarCommon($(elements));
});
company.exists(elements => {
    new CompanyCommon($(elements));
});
commonModal.exists(elements => {
    new ModalCommon($(elements));
});
deletes.exists(elements => {
    new DeleteCommons($(elements));
});
analysis.exists(elements => {
    new AnalysisCommon($(elements));
});
lineApi.exists(elements => {
    new LineApiCommon($(elements));
});
adminCompany.exists(elements => {
    new AdminCompanyCommon($(elements));
});
login.exists(elements => {
    new LoginCommon($(elements));
});
judgementModal.exists(elements => {
    new judgementModalCommon($(elements));
});
sorts.exists(elements => {
    new SortCommon($(elements));
});
entryIndex.exists(elements => {
    new EntryIndexCommon($(elements));
});
taskCompany.exists(elements => {
    new TaskCompanyCommon($(elements));
});
hamburgurMenu.exists( el => new HamburgurMenu( $( el ) ) );