const env = process.env.NODE_ENV || 'local';

function config(setting) {
  return setting[env];
}

const apiUrl = config({
    local: 'http://localhost:8020/',
    development: 'http://localhost:8020/',
    test: 'https://ian.saiyowensystem.com/',
    production: 'https://saiyowensystem.com/',
});

export default {
  apiUrl,
};
