import $ from 'jquery';

export default class {
  static addText(res, count) {
    let box = '';
    let names = `name="questionnaire_content_content-${count}_${res}"`;
    let requireName = `name="questionnaire_content_required${count}"`;
    let idOnName = `id="switchon${count}"`;
    let idOffName = `id="switchoff${count}"`;
    let forOnName = `for="switchon${count}"`;
    let forOffName = `for="switchoff${count}"`;

    box = `
      <div class="group_box">
        <label>テキストボックス</label>
        <input type="text" ${names} class="form-control js-content_id" placeholder="内容を記入して下さい。 例：本日の感想を一言で記入して下さい。">
        <div class="required_group">
          <label class="required_title">必須選択</label>
          <div class="required_box">
            <input type="radio" ${idOnName} ${requireName} class="required_labelOn" value="2" checked>
            <label class="required_label" ${forOnName}>on</label>
            <input type="radio" ${idOffName} ${requireName} class="required_labelOff" value="1">
            <label class="required_label" ${forOffName}>off</label>
          </div>
        </div>
      </div>
    `;

    return box;
  }

  static addTextArea(res, count) {
    let box = '';
    let names = `name="questionnaire_content_content-${count}_${res}"`;
    let requireName = `name="questionnaire_content_required${count}"`;
    let idOnName = `id="switchon${count}"`;
    let idOffName = `id="switchoff${count}"`;
    let forOnName = `for="switchon${count}"`;
    let forOffName = `for="switchoff${count}"`;
    box = `
      <div class="group_box">
        <label>テキストエリア</label>
        <textarea ${names} class="form-control js-content_id" placeholder="内容を記入して下さい。 例：本日の感想を記入して下さい。"></textarea>
        <div class="required_group">
          <label class="required_title">必須選択</label>
          <div class="required_box">
            <input type="radio" ${idOnName} ${requireName} class="required_labelOn" value="2" checked>
            <label class="required_label" ${forOnName}>on</label>
            <input type="radio" ${idOffName} ${requireName} class="required_labelOff" value="1">
            <label class="required_label" ${forOffName}>off</label>
          </div>
        </div>
      </div>
    `;

    return box;
  }

  static addCheck(res, count) {
    let box = '';
    let names = `name="questionnaire_content_content-${count}_${res}[]"`;
    let requireName = `name="questionnaire_content_required${count}"`;
    let idOnName = `id="switchon${count}"`;
    let idOffName = `id="switchoff${count}"`;
    let forOnName = `for="switchon${count}"`;
    let forOffName = `for="switchoff${count}"`;
    box = `
      <div class="group_box js-add_child_box">
        <div class="js-add_child_content_box">
          <label>内容</label>
          <textarea ${names} class="form-control js-content_id" placeholder="複数チェックさせたい内容を記入して下さい。 例：下記事項に当てはまる物にチェックをお願い致します。"></textarea>
          <div class="required_group">
            <label class="required_title">必須選択</label>
            <div class="required_box">
              <input type="radio" ${idOnName} ${requireName} class="required_labelOn" value="2" checked>
              <label class="required_label" ${forOnName}>on</label>
              <input type="radio" ${idOffName} ${requireName} class="required_labelOff" value="1">
              <label class="required_label" ${forOffName}>off</label>
            </div>
          </div>
          <div class="js-select_add_boxs">
            <label>回答チェックボックス</label>
            <input type="checkbox" disabled>
            <input type="text" ${names} class="js-select_add_box form-control" placeholder="内容を記入して下さい。 例：良いと思った">
          </div>
        </div>
      </div>
      <button type="button" class="js-add_child btn btn-info" value="1">ボックス追加</button>
      <button type="button" class="js-delete_child btn btn-danger" disabled>ボックス削除</button>
    `;

    return box;
  }

  static addRadio(res, count) {
    let box = '';
    let names = `name="questionnaire_content_content-${count}_${res}[]"`;
    let requireName = `name="questionnaire_content_required${count}"`;
    let idOnName = `id="switchon${count}"`;
    let idOffName = `id="switchoff${count}"`;
    let forOnName = `for="switchon${count}"`;
    let forOffName = `for="switchoff${count}"`;

    box = `
      <div class="group_box js-add_child_box">
        <div class="js-add_child_content_box">
          <label>内容</label>
          <textarea ${names} class="form-control js-content_id" placeholder="1つだけ選択させたい内容を記入して下さい。 例：下記事項の中で最も自分の意見に沿っているものを一つお選び下さい。"></textarea>
          <div class="required_group">
            <label class="required_title">必須選択</label>
            <div class="required_box">
              <input type="radio" ${idOnName} ${requireName} class="required_labelOn" value="2" checked>
              <label class="required_label" ${forOnName}>on</label>
              <input type="radio" ${idOffName} ${requireName} class="required_labelOff" value="1">
              <label class="required_label" ${forOffName}>off</label>
            </div>
          </div>
          <div class="js-select_add_boxs">
            <label>回答ラジオボックス</label>
            <input type="radio" disabled>
            <input type="text" ${names} class="js-select_add_box form-control" placeholder="選択させる内容を記入して下さい。 例：良いと思った。">
          </div>
        </div>
      </div>
      <button type="button" class="js-add_child btn btn-info" value="2">ボックス追加</button>
      <button type="button" class="js-delete_child btn btn-danger" disabled>ボックス削除</button>
    `;

    return box;
  }

  static addSelect(res, count) {
    let box = '';
    let names = `name="questionnaire_content_content-${count}_${res}[]"`;
    let requireName = `name="questionnaire_content_required${count}"`;
    let idOnName = `id="switchon${count}"`;
    let idOffName = `id="switchoff${count}"`;
    let forOnName = `for="switchon${count}"`;
    let forOffName = `for="switchoff${count}"`;
    box = `
      <div class="group_box js-add_child_box">
        <div class="js-add_child_content_box">
          <label>内容</label>
          <textarea ${names} class="form-control js-content_id" placeholder="1つだけ選択させたい内容を記入して下さい。 例：御社の志望動機で近いものをお選び下さい。"></textarea>
          <div class="required_group">
            <label class="required_title">必須選択</label>
            <div class="required_box">
              <input type="radio" ${idOnName} ${requireName} class="required_labelOn" value="2" checked>
              <label class="required_label" ${forOnName}>on</label>
              <input type="radio" ${idOffName} ${requireName} class="required_labelOff" value="1">
              <label class="required_label" ${forOffName}>off</label>
            </div>
          </div>
          <div class="js-select_add_boxs">
            <label>回答セレクトボックス</label>
            <input type="text" ${names} class="js-select_add_box form-control" placeholder="選択させる内容を記入して下さい。 例：使用言語が自分と合っていた。">
          </div>
        </div>
      </div>
      <button type="button" class="js-add_child btn btn-info" value="3">ボックス追加</button>
      <button type="button" class="js-delete_child btn btn-danger" disabled>ボックス削除</button>
    `;

    return box;
  }

  static addChildCheck(parentName) {
    let box = '';
    let names = `name="${parentName}"`;
    box = `
      <div class="js-select_add_boxs">
        <label>チェックボックス</label>
        <input type="checkbox" disabled>
        <input type="text" ${names} class="js-select_add_box form-control" placeholder="内容を記入して下さい。 例：良いと思った">
      </div>
    `;

    return box;
  }

  static addChildRadio(parentName) {
    let box = '';
    let names = `name="${parentName}"`;
    box = `
      <div class="js-select_add_boxs">
        <label>ラジオボックス</label>
        <input type="radio" disabled>
        <input type="text" ${names} class="js-select_add_box form-control" placeholder="選択させる内容を記入して下さい。 例：良いと思った。">
      </div>
    `;

    return box;
  }

  static addChildSelect(parentName) {
    let box = '';
    let names = `name="${parentName}"`;
    box = `
      <div class="js-select_add_boxs">
        <label>セレクトボックス</label>
        <input type="text" ${names} class="js-select_add_box form-control" placeholder="選択させる内容を記入して下さい。 例：使用言語が自分と合っていた。">
      </div>
    `;

    return box;
  }
}
