import $ from 'jquery';
import 'remodal';
import openModal from '../../utils/openModal';

export default class {
  constructor($target) {
    this.$target = $target;
    this.bind();
  }

  bind() {
    this.$target.on('click', '.js-openRemodal', ((e) => {
      const $target = $(e.currentTarget);
      const targetVal = $target.attr('data-val');
      const targetPage = $target.attr('data-page');
      const csrf = $('[name="csrf-token"]').attr('content');
      $('.js-delete_form').append(`<input name="_token" type="hidden" value="${csrf}" class="js-src">`)
      $('.js-delete_form').attr('action', `/company/${targetPage}/delete/${targetVal}`);
      openModal($target.attr('data-modalName'));
    }));
  }
}
