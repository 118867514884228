import $ from 'jquery';
import openModal from '../../utils/openModal';
import TaskCompanyModel from '../../model/TaskCompanyModel';
import taskList from '../../utils/taskList'

const taskCompany = new TaskCompanyModel();

export default class {
  constructor($target) {
    this.$target = $target;
    this.bind();
  }

  bind() {
    this.$target.on('click', '.js-openRemodal', ((e) => {
      const $target = $(e.currentTarget);
      const userId = $target.attr('data-userId');
      const taskDeadline = $target.attr('data-taskDeadline');
      $('.js-add_task_list').remove();
      let param = {};
      param.user_id = userId;
      param.task_deadline = taskDeadline;
      (async() => {
        try {
          const res = await taskCompany.taskcompanyindex(param);
          $('.js-task_select').append(taskList.addTaskList(res));
          console.log(res);
          openModal($target.attr('data-modalName'));
        } catch(e) {
          console.log(e);
        };
      })();
    }));
  }
}
