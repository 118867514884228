import $ from 'jquery';
import { request, apiUrl, params } from '../utils';

const baseUrl = 'api/line/message';


export default class CalendarModel {

  linessend = async (params) => {
    try {
      console.log(params);
      // const param = $.param($params);
      // const res = await request.GET(apiUrl.fetchUrl(`${baseUrl}`, `send?${param}`));
      const res = await request.POST(apiUrl.fetchUrl(`${baseUrl}`, 'send'), params);
      return res;
    } catch (e) {
      throw new Error(e);
    }
  }

  lineshow = async ($id) => {
    try {
      const param = $.param($id);
      const res = await request.GET(apiUrl.fetchUrl(`${baseUrl}`, `receive?${param}`));
      return res;
    } catch (e) {
      throw new Error(e);
    }
  }

}
