import $ from 'jquery';
import openModal from '../../utils/openModal';

export default class {
  constructor($target) {
    this.$target = $target;
    this.bind();
  }

  bind() {
    this.$target.on('click', '.js-judgement_select', ((e) => {
      const $target = $(e.currentTarget);
      const $targetVal = $target.find('option:selected').val();
    }));

    this.$target.on('click', '.js-openRemodal', ((e) => {
      const $target = $(e.currentTarget);
      const judgementSelected = $('.js-judgement_select option:selected').val();
      console.log(judgementSelected);
      openModal($target.attr('data-modalName'));
    }));
  }
}
