import $ from 'jquery';
import 'remodal';

export default (id) => {
  const options = {
    // 背景クリック不可
    closeOnOutsideClick: false,
    hashTracking: false,
  };
  $(`[data-remodal-id=${id}]`).remodal(options).open();
};
