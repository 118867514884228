import $ from 'jquery';
import openModal from '../../utils/openModal';

export default class {
  constructor($target) {
    this.$target = $target;
    this.bind();
  }

  bind() {
    this.$target.on('click', '.js-openRemodal', ((e) => {
      const $target = $(e.currentTarget);
      const companyID = $target.attr('data-companyId');
      const line_id = $target.attr('data-line_id');
      const login_channel_id = $target.attr('data-login_channel_id');
      const login_channel_secret = $target.attr('data-login_channel_secret');
      const message_channel_secret = $target.attr('data-message_channel_secret');
      const message_channel_access_token = $target.attr('data-message_channel_access_token');

      $('.js-company_id').val(companyID);
      $('.js-line_id').val(line_id);
      $('.js-line_login_channel_id').val(login_channel_id);
      $('.js-line_login_channel_secret').val(login_channel_secret);
      $('.js-line_message_channel_secret').val(message_channel_secret);
      $('.js-line_message_channel_access_token').val(message_channel_access_token);
      openModal($target.attr('data-modalName'));
    }));
  }
}
