import $ from 'jquery';
import openModal from '../../utils/openModal';
import Sortable from 'sortablejs';

export default class {
  constructor($target) {
    this.$target = $target;
    this.bind();
  }

  bind() {
    /**
     * ソート機能
     * @type {[type]}
     */
    this.$target.on('click', '.js-sort_btn', ((e) => {
      const $target = $(e.currentTarget);
      let sorts = '';
      if($target.attr('data-sortVal') === 'desc') {
        sorts = $target.attr('data-sortVal');
      } else {
        sorts = $target.attr('data-sortVal');
      }
      $target.after(
        `
          <input type="hidden" name="${$target.attr('name')}" value="${sorts}">
        `
      );
      $target.val('');
      $target.attr('name', '');
    }));
  }
}
