import $ from 'jquery';
import moment from 'moment';

export default class {
  static userCalendar(day, start_time, end_time, week) {
    let calendar = '';
    calendar = `
      <div>
        <h5>${day}</h5>
        <div>${start_time}~${end_time}</div>
        <input type="hidden" name="calendar_user_event_day" value="${day}">
        <input type="hidden" name="calendar_user_event_start_time" value="${start_time}">
        <input type="hidden" name="calendar_user_event_end_time" value="${end_time}">
        <input type="hidden" name="calendar_user_event_week" value="${week}">
      </div>
    `;

    return calendar;
  }

  static companyCalendar(day, start_time, end_time, week, all) {
    let calendar = '';
    const selectTime = this.timeBox(all);
    calendar = `
      <div>
        <h5>${day}</h5>
        <div>
          <label>開始時間</label>
          <div class="start_time_font">${start_time}</div>
        </div>
        <div>
          <label>終了時間</label>
          <div class="form-group">
            ${selectTime}
          </div>
        </div>
        <div id="js-calendar_company_event_time_area">
          <label for="calendar_company_event_time-select">利用者に対応する時間を指定してください</label><br>
          <select name="calendar_company_event_time" id="js-calendar_company_event_time" class="mb-3">
            <option value="1800000">0.5時間</option>
          </select>
        </div>
        <input type="hidden" name="calendar_company_event_day" value="${day}">
        <input type="hidden" name="calendar_company_event_start_time" value="${start_time}">
        <input type="hidden" name="calendar_company_event_week" value="${week}">
      </div>
    `;

    return calendar;
  }

  static companyOnCompany(day, start_time, end_time, week, now) {
    const option = this.myFunc(start_time, end_time, now);

    let calendar = '';
    calendar = `
      <div>
        <h5>${day}</h5>

        <div>
          <label>開始時間</label>
          <div>
            <select name="calendar_company_event_start_time" class="mb-3 start_option">
              ${option['start_option']}
            </select>
          </div>
        </div>

        <div>
          <label>終了時間</label>
          <div>
            <select name="calendar_company_event_end_time" class="mb-3 end_option">
              ${option['end_option']}
            </select>
          </div>
        </div>

        <div>
          <label for="calendar_company_event_time">利用者に対応する時間を指定してください</label><br>
          <select name="calendar_company_event_time" class="mb-3 use_option">
            <option value="1800000">0.5時間</option>
          </select>
        </div>

        <input type="hidden" name="calendar_company_event_day" value="${day}">
        <input type="hidden" name="calendar_company_event_week" value="${week}">
      </div>
    `;

    return calendar;
  }
  static myFunc(start_time, end_time, now){
    // 開始時間のセレクトボックス作成
    let start = '';
    if(moment(start_time) < moment(now)){
      start = moment(now).add(1, 'hour').format(`YYYY-MM-DD HH:00`);
    }else{
      start = start_time;
    }
    let end = moment(end_time).format(`YYYY-MM-DD 23:30`);
    let start_option = '';
    for(let target = moment(start).clone(); target.isBefore(end); target.add(30, 'minutes')){
      start_option = `${start_option}<option value="${target.format("HH:mm")}">${target.format("HH:mm")}</option>`;
    }

    // 終了時間のセレクトボックス作成
    start = moment(start).add(30, 'm');
    let end_option = '';
    for(let target = moment(start).clone(); target.isSameOrBefore(end); target.add(30, 'minutes')){
      end_option = `${end_option}<option value="${target.format("HH:mm")}">${target.format("HH:mm")}</option>`;
    }

    // 利用時間のセレクトボックス作成
    let i = 1800000;
    let use_time = 0.5;
    let use_option = '';
    for(let target = start.clone(); target.isSameOrBefore(end_time); target.add(30, 'minutes')){
      use_option = `${use_option}<option value="${i}">${use_time}時間</option>`;
      use_time = use_time + 0.5;
      i = i + 1800000;
    }

    return {
      'start_option': start_option,
      'end_option': end_option,
      'use_option': use_option,
    };
  }

  static cancelCompanyCalendar(day, start_time, end_time, week) {
    let calendar = '';
    const js_start_time = moment(start_time).format('HH:mm');
    const js_end_time = moment(end_time).format('HH:mm');
    calendar = `
      <div>
        <h5>${day}</h5>
        <div>
          <label>開始時間</label>
          <div class="form-group">${js_start_time}</div>
        </div>
        <div>
          <label>終了時間</label>
          <div class="form-group">
            ${js_end_time}
          </div>
        </div>
        <input type="hidden" name="calendar_company_event_day" value="${day}">
        <input type="hidden" name="calendar_company_event_start_time" value="${start_time}">
        <input type="hidden" name="calendar_company_event_week" value="${week}">
      </div>
    `;

    return calendar;
  }

  static showCompanyCalendar(day, start_time, end_time, week, title) {
    let calendar = '';
    const js_start_time = moment(start_time).format('HH:mm');
    const js_end_time = moment(end_time).format('HH:mm');
    calendar = `
      <div>
        <h5>${day}</h5>
        <div class="form-group">
          <h6>${title[2]}様の${title[0]}</h6>
        </div>
        <div>
          <label>開始時間</label>
          <div class="form-group">${js_start_time}</div>
        </div>
        <div>
          <label>終了時間</label>
          <div class="form-group">
            ${js_end_time}
          </div>
        </div>
        <input type="hidden" name="calendar_company_event_day" value="${day}">
        <input type="hidden" name="calendar_company_event_start_time" value="${start_time}">
        <input type="hidden" name="calendar_company_event_week" value="${week}">
      </div>
    `;

    return calendar;
  }

  static timeBox(all) {
    const start = moment(all).add(30, 'm').format('YYYY-MM-DD HH:mm:ss');
    const startMolding = moment(start);
    const end = moment(all).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    const endMolding = moment(end);
    let optiontime = '';
    for (let target = startMolding.clone(); target.isBefore(endMolding); target.add(30, "minutes")) {
      optiontime = `${optiontime}<option value="${target.format("HH:mm")}">${target.format("HH:mm")}</option>`;
    }
    let timeSelect = '';
    timeSelect = `
      <select name="calendar_company_event_end_time" class="form-control w-25 js-end_time_change">
        ${optiontime}
      </select>
    `;

    return timeSelect;
  }

  static userTimeBox(day, start_time, end_time, week, userEventDay, userEventStart_time, userEventEnd_time, time, calender_id, user_event, manager_id) {
    const start = moment(start_time).format('YYYY-MM-DD HH:mm:ss');
    const startMolding = moment(start);
    const end = moment(start_time).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    const endMolding = moment(end);
    let options = '';
    let timeBox = '';
    let start_change_time = '';
    let current = -1;
    let frist = 0;
    let remove_arr = [];
    let event_time = '';
    let first_end_count = 0;

    // 00:00を無理やりDate型に変換
    user_event.forEach((v, k) => {
      if(day === v.calendar_user_event_day && Number(manager_id) === Number(v.user.child_company.id)){
        const new_userEventDay = String(v.calendar_user_event_day).split('/');
        const new_userEventStartTime = String(v.calendar_user_event_start_time).split(':');
        const new_userEventEndTime = String(v.calendar_user_event_end_time).split(':');
  
        let user_event_start = moment([
          Number(new_userEventDay[0]),
          Number(new_userEventDay[1] - 1),
          Number(new_userEventDay[2]),
          Number(new_userEventStartTime[0]),
          Number(new_userEventStartTime[1]),
        ]).format('YYYY-MM-DD HH:mm');
    
        let user_event_end = moment([
          Number(new_userEventDay[0]),
          Number(new_userEventDay[1] - 1),
          Number(new_userEventDay[2]),
          Number(new_userEventEndTime[0]),
          Number(new_userEventEndTime[1]),
        ]).format('YYYY-MM-DD HH:mm');
  
        for(
          let i = moment(user_event_start).clone();
          i.isSameOrBefore(user_event_end);
          i.add(30, 'm')
        ){
          remove_arr.push(moment(i).format('HH:mm'));
        }

        for(
          let t = time;
          0 <= t;
          t = t - 1800000
        ){
          user_event_start = moment(user_event_start).add(-30, 'm').format('YYYY-MM-DD HH:mm');
          remove_arr.push(moment(user_event_start).format('HH:mm'));
        }
      }
    });

    // startからendまで30分ずつ加算してstart > endの関係になったら処理止める
    for (let target = startMolding.clone(); target.isBefore(endMolding); target.add(30, 'm')) {
      let deleteTime = $.inArray(target.format('HH:mm'), userEventStart_time, current + 1);
      if(target.format('HH:mm') < moment(end_time).format('HH:mm')) {
        if(userEventStart_time[deleteTime] !== target.format('HH:mm') && userEventEnd_time[deleteTime] !== target.format('HH:mm')) {
          if(frist === 1) {
            start_change_time = moment(target);
            start_change_time = moment(start_change_time).format('HH:mm');
          }else if(frist === 0) {
            start_change_time = moment(target);
            start_change_time = moment(start_change_time).add(30, 'm').format('HH:mm');
          }
          // 一致したらoptionに追加しないconsole.log(111)
          if(
            day === moment(target.format("YYYY-MM-DD HH:mm:ss")).add(time/3600000, 'hours').format('YYYY/MM/DD') &&
            target.format("YYYY-MM-DD HH:mm:ss") <= moment(end_time).add(-time/3600000, 'hours').format('YYYY-MM-DD HH:mm:ss') &&
            target.format("HH:mm") !== remove_arr.find(element => element === target.format("HH:mm"))
          ){
            options = `${options}<option value="${target.format("HH:mm")}" data-val="${target.format('YYYY-MM-DD HH:mm')}">${target.format("HH:mm")}</option>`;
            if(Number(first_end_count) === 0){
              event_time = moment(target.format("YYYY-MM-DD HH:mm:ss")).add(time/3600000, 'hours').format('HH:mm')
            }
            first_end_count++;
          }
        }
        frist++;
      }
    }

    // optionsの中身が空なら
    if(options){
      document.querySelector('.btn-success').style.display = 'inline';
      timeBox = `
      <div>
        <div>
          <label>面接予約日時</label>
          <div>${day}</div>
          <input type="hidden" name="calender_id" value=${calender_id}>
          <input type="hidden" name="calendar_user_event_day" value=${day}>
          <input type="hidden" name="calendar_user_event_week" value=${week}>
        </div>
        <div class="form-group">
          <label>開始時間</label>
          <select class="form-control w-25 js-start_time_modal" name="calendar_user_event_start_time">
            ${options}
          </select>
        </div>
        <div class="form-group">
          <label>終了時間</label>
          <div class="start_time_font js-end_time_modal">${event_time}</div>
          <input type="hidden" name="calendar_user_event_end_time" class="js-calendar_user_event_end_time" value="${event_time}">
        </div>
      </div>
    `;
    }else{
      document.querySelector('.btn-success').style.display = 'none';
      timeBox = `
      <div>
        <div>
          <label>面接予約日時</label>
          <div>${day}</div>
          <input type="hidden" name="calender_id" value=${calender_id}>
          <input type="hidden" name="calendar_user_event_day" value=${day}>
          <input type="hidden" name="calendar_user_event_week" value=${week}>
        </div>
        <div class="form-group">
          予約できる時間がありません。
      </div>
      `;
    }

    return timeBox;
  }
}
