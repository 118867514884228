import validate_config from "../config/validate_config";

export default class Validate{

  static validate(params){
    // 検証し、trueならエラー文を配列に格納
    let result = [];
    params.forEach((val, key) => {
      switch(val.name){
        case 'task_company_name':
        case 'task_company_remarks':
          if(/^$/.test(val.value) === true){
            result.push(validate_config[val.name]+'は空では値を送信できません。')
          }
          if(/^ |^　| $|　$/.test(val.value) === true){
            result.push(validate_config[val.name]+'は前後に空白が存在します。')
          }
        break;
      }
    });

    // もし配列が空なら送信
    if(result.length === 0){
      document.querySelector('.js-validate_submit').type = 'submit';
    }else{
      result.forEach(((val, key) => {
        let div = document.createElement('div');
        div.innerHTML =val;
        div.className =val+key;
        if(document.querySelector('.'+val+key)){
          document.querySelector('.'+val+key).remove();
        }
        document.querySelector('.js-validate_error').append(div);
      }))
    }
  }

}