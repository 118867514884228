import $ from 'jquery';
import { request, apiUrl, params } from '../utils';

const baseUrl = 'api/task_company';


export default class TaskCompanyModel {
  taskcompanyindex = async (params) => {
    try {
      const param = $.param(params);
      const res = await request.GET(apiUrl.fetchUrl(`${baseUrl}`, `index?${param}`));

      return res;
    } catch (e) {
      throw new Error(e);
    }
  }

}
