import $ from 'jquery';
import { request, apiUrl, params } from '../utils';

const baseUrl = 'api/user';


export default class UserModel {

  userupdate = async (params) => {
    try {
      console.log(params);
      const res = await request.POST(apiUrl.fetchUrl(`${baseUrl}`, 'update'), params);
      return res;
    } catch (e) {
      throw new Error(e);
    }
  }

}
