import $, { get } from 'jquery';
import datepicker from 'bootstrap-datepicker';
import moment from 'moment';
import CompanyhistoryModel from '../../model/CompanyhistoryModel';
import UserModel from '../../model/UserModel';
// import EntryModel from '../../model/EntryModel';
import openModal from '../../utils/openModal';
import taskList from '../../utils/taskList'
import Load from '../../utils/load';
import renderHTML from '../../utils/renderHTML';

const companyhistory = new CompanyhistoryModel();
const user = new UserModel();
// const entry = new EntryModel();

export default class {
  constructor($target) {
    this.$target = $target;
    this.bind();
  }

  bind() {
    // バージョンの関係でここに記述
    $.extend($.fn.datepicker.dates , {
      ja: {
        days: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日', '日曜日'],
        daysShort: ['日', '月', '火', '水', '木', '金', '土', '日'],
        daysMin: ['日', '月', '火', '水', '木', '金', '土', '日'],
        months: ['1月', '2月', '3月', '4月', '5月', '6月','7月', '8月', '9月', '10月', '11月', '12月'],
        monthsShort: ['1月', '2月', '3月', '4月', '5月', '6月','7月', '8月', '9月', '10月', '11月', '12月']
      }
    });

    // タイムピッカー
    $('.js-timepicker').datepicker({
      format: 'yyyy/mm/dd',
      language: 'ja',
      autoclose: true,
      forceParse: true,
      setValue: '2021/02/22',
      startDate: moment().format("YYYY/MM/DD")
    });

    $(document).ready((() => {
      $('.other_show').find('[name=task_name]').attr('disabled', true);
      $('.other_show').find('[name=task_name]').attr('name', '');
      // 表示時にDOM出し分け判定
      if(Number($('.js-chenge_radio:checked').val()) === 1) {
        $('.js-other').prop('disabled', true);
      } else {
        $('.js-other').prop('disabled', false);
        $('.js-other').attr('name', 'task_name');
      }
    }));

    this.$target.on('click', '.js-chenge_radio', ((e) => {
      const $target = $(e.currentTarget);
      if(Number($target.val()) === 1) {
        $('.js-selectbox').removeClass('other_show');
        $('.js-task_task_company_id').removeClass('other_show');
        $('.js-textbox').addClass('other_show');
        $('.js-task_select').attr('name', 'task_name');
        $('.js-task_select').prop('disabled', false);
        $('.js-other').attr('name', '');
        $('.js-other').prop('disabled', true);
      } else {
        $('.js-textbox').removeClass('other_show');
        $('.js-selectbox').addClass('other_show');
        $('.js-task_task_company_id').addClass('other_show');
        $('.js-task_select').attr('name', '');
        $('.js-task_select').prop('disabled', true);
        $('.js-other').attr('name', 'task_name');
        $('.js-other').prop('disabled', false);
      }
    }));

    /**
     * カレンダー変更したら値挿入
     * @type {[type]}
     */
    this.$target.on('change', '.js-timepicker', ((e) => {
      const $target = $(e.currentTarget);
      const time = $('.js-deadline_time').val();
      let allTime = '';
      if(time) {
        allTime = `${$target.val()} ${time}`;
      } else {
        allTime = $target.val();
      }
      $('.js-task_deadline').val(allTime);
    }));

    /**
     * カレンダー時間を変更を行ったら
     * @type {[type]}
     */
    this.$target.on('change', '.js-deadline_time', ((e) => {
      const $target = $(e.currentTarget);
      const targetVal = $target.val();
      const deadline = $('.js-task_deadline').val().split(' ');
      let allTime = '';
      if(deadline) {
        allTime = `${deadline[0]} ${$target.val()}`;
      } else {
        allTime = $target.val();
      }
      $('.js-task_deadline').val(allTime);
    }));

    this.$target.on('change', '.js-task_select', ((e) => {
      const $target = $(e.currentTarget);
      // const targetDataVal = $('.js-task_select option:selected').attr('data-select_val');
      $('.js-task_values').remove();
      $('.js-task_select option:selected').each(function(i,x) {
        const dataId = $(x).attr('data-select_val');
        const dataVal = $(x).val();
        $('.js-selectbox').append(taskList.addDataValues(dataId, dataVal));
      });
    }));

    this.$target.on('click', '.js-document_check_flg', ((e) => {
      const $target = $(e.currentTarget);
      const name = $target.attr('name');
      if($target.prop('checked')) {
        $target.val(2);
        $(`[data-name="${name}"]`).remove();
      } else {
        $target.val(1);
        $('.js-check_all').append(`<input type="hidden" name="${name}" data-name="${name}" value="1">`);
      }
    }));

    /**
     * EntryController@edit の企業履歴の非同期通信関数
     */
    window.addEventListener('load', (() => {
      let res = '';
      let param = {
        'id': document.querySelector('[name="user_id"]').value,
        'page': 1,
        'sort': 'desc',
      };

      // 子要素削除
      document.querySelector('#async_await_tr').innerHTML='';

      // d-noneが存在するなら削除
      switch(true){
        case document.querySelector('.current_page_1').classList.contains('d-none'):
          document.querySelector('.current_page_1').classList.remove('d-none');
          break;
        case document.querySelector('.current_page_2').classList.contains('d-none'):
          document.querySelector('.current_page_1').classList.remove('d-none');
          break;
        case document.querySelector('.current_page_3').classList.contains('d-none'):
          document.querySelector('.current_page_1').classList.remove('d-none');
          break;
      }

      (async () => {
        try{
          // データ取得
          Load.loadStart();
          res = await companyhistory.companyhistoryindex(param);
          Load.loadEnd();

          // HTMLに描画
          res.data.forEach((val, key) => {
            let element = `
              <tr>
                <td>${val.name}</td>
                <td>${moment(val.updated_at).format("YYYY-MM-DD HH:mm:ss")}</td>
              <tr/>
            `;
            document.querySelector('#async_await_tr').insertAdjacentHTML('beforeend', element);
          });

          // 値格納
          document.querySelector('.current_page_1').setAttribute('data-page', res.current_page + 0)
          document.querySelector('.current_page_2').setAttribute('data-page', res.current_page + 1)
          document.querySelector('.current_page_3').setAttribute('data-page', res.current_page + 2)
          document.querySelector('.current_page_1').innerHTML=res.current_page + 0
          document.querySelector('.current_page_2').innerHTML=res.current_page + 1
          document.querySelector('.current_page_3').innerHTML=res.current_page + 2
          document.querySelector('.last_page').setAttribute('data-page', res.last_page);

          // last_pageが3未満の場合
          switch(res.last_page){
            case 1:
              document.querySelector('.current_page_2').classList.add('d-none')
              document.querySelector('.current_page_3').classList.add('d-none')
              break;
            case 2:
              document.querySelector('.current_page_3').classList.add('d-none')
              break;
          }
          // active追加
          document.querySelector('.current_page_1').parentNode.classList.add('active')

        }catch(e){
          console.log(e);
        }
      })();

      document.querySelectorAll('.page_link_input').forEach((val, key) => {
        let res = '';
        val.addEventListener('click', (e) => {
          // クリックしたターゲットの値からAPI訪問
          let target = e.currentTarget;
          param.page = target.getAttribute('data-page');
          (async () => {
            Load.loadStart();
            res = await companyhistory.companyhistoryindex(param);
            Load.loadEnd();

            // 子要素削除
            document.querySelector('#async_await_tr').innerHTML='';

            // HTMLに描画
            res.data.forEach((val, key) => {
              let element = `
                <tr>
                  <td>${val.name}</td>
                  <td>${moment(val.updated_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                <tr/>
              `;
              document.querySelector('#async_await_tr').insertAdjacentHTML('beforeend', element);
            });

            // for文で3個リレーションボタン作成
            for(let i = 1; i <= 3; i++){
              // d-noneを削除
              document.querySelector('.current_page_1').classList.remove('d-none')
              document.querySelector('.current_page_2').classList.remove('d-none')
              document.querySelector('.current_page_3').classList.remove('d-none')
              // 現在のページで分岐
              switch(res.current_page){
                case 1: // 現在のページが1の場合 〇
                  document.querySelector('.current_page_1').setAttribute('data-page', res.current_page + 0)
                  document.querySelector('.current_page_2').setAttribute('data-page', res.current_page + 1)
                  document.querySelector('.current_page_3').setAttribute('data-page', res.current_page + 2)
                  document.querySelector('.current_page_1').innerHTML=res.current_page + 0
                  document.querySelector('.current_page_2').innerHTML=res.current_page + 1
                  document.querySelector('.current_page_3').innerHTML=res.current_page + 2
                  switch(res.last_page){
                    case 1: // 最後のページが1の時 〇
                      document.querySelector('.current_page_2').classList.add('d-none')
                      document.querySelector('.current_page_3').classList.add('d-none')
                      break;
                    case 2: // 最後のページが2の時 〇
                      document.querySelector('.current_page_3').classList.add('d-none')
                      break;
                  }
                  break;
                case res.last_page: // 現在のページが最後の時 〇
                  document.querySelector('.current_page_1').setAttribute('data-page', res.current_page - 2)
                  document.querySelector('.current_page_2').setAttribute('data-page', res.current_page - 1)
                  document.querySelector('.current_page_3').setAttribute('data-page', res.current_page + 0)
                  document.querySelector('.current_page_1').innerHTML=res.current_page - 2
                  document.querySelector('.current_page_2').innerHTML=res.current_page - 1
                  document.querySelector('.current_page_3').innerHTML=res.current_page + 0
                  switch(res.last_page){
                    case 2: // 最後のページが2の時 〇
                      document.querySelector('.current_page_1').classList.add('d-none')
                      break;
                  }
                  break;
                default: // 現在のページが1と最後以外 〇
                  document.querySelector('.current_page_1').setAttribute('data-page', res.current_page - 1)
                  document.querySelector('.current_page_2').setAttribute('data-page', res.current_page + 0)
                  document.querySelector('.current_page_3').setAttribute('data-page', res.current_page + 1)
                  document.querySelector('.current_page_1').innerHTML=res.current_page - 1
                  document.querySelector('.current_page_2').innerHTML=res.current_page + 0
                  document.querySelector('.current_page_3').innerHTML=res.current_page + 1
              }
            }
            document.querySelector('.last_page').setAttribute('data-page', res.last_page);
            switch(true){
              case Number(document.querySelector('.current_page_1').getAttribute('data-page')) === res.current_page:
                document.querySelector('.current_page_1').parentNode.classList.add('active')
                break;
              case Number(document.querySelector('.current_page_2').getAttribute('data-page')) === res.current_page:
                document.querySelector('.current_page_2').parentNode.classList.add('active')
                break;
              case Number(document.querySelector('.current_page_3').getAttribute('data-page')) === res.current_page:
                document.querySelector('.current_page_3').parentNode.classList.add('active')
                break;
            }
          })();
        });
      });
    }));

    this.$target.on('click', '.js-exampleInputEmail1_submit', ((e) => {
      let param = {};
      param.id = $('.js-user_id').val();
      param.name = $('.js-exampleInputEmail1').val();
      (async() => {
        try {
          Load.loadStart();
          const res = await companyhistory.entryhistorysave(param);
          Load.loadEnd();
          if(res) {
            $('.js-exampleInputEmail1').val('');
            $('.js-title').text('履歴登録');
            $('.js-content').text('履歴登録が完了しました、再読み込みして下さい');
            openModal('completeJsModal');
          }
        } catch(e) {
          console.log(e);
        };
      })();
    }));

    this.$target.on('click', '.js-user_edit_btn', ((e) => {
      const $target = $(e.currentTarget);
      $('select[name="year_info"]').attr('disabled', false);
      $('input:radio[name="venue_status"]').attr('disabled', false);
      let param = {};
      if($target.hasClass('edit_active')) {
        param.id = $target.attr('data-user');
        param.name = $('.js-user_name').val().replace(/^(　)+/,'').replace(/(　)+$/,''); // 氏名前と後の全角スペースを消す
        param.name_kana = $('.js-user_name_kana').val().replace(/^(　)+/,'').replace(/(　)+$/,'');
        param.sex = $('.js-user_sex').val().replace(/^(　)+/,'').replace(/(　)+$/,'');
        param.via = $('.js-user_via').val().replace(/^(　)+/,'').replace(/(　)+$/,'');
        param.school = $('.js-user_school').val().replace(/^(　)+/,'').replace(/(　)+$/,'');
        param.undergraduate = $('.js-user_undergraduate').val().replace(/^(　)+/,'').replace(/(　)+$/,'');
        param.study_type = $('.js-user_study_type').val().replace(/^(　)+/,'').replace(/(　)+$/,'');
        param.year_info = $('select[name="year_info"] option:selected').val();
        // param.venue_status = $('input:radio[name="venue_status"]:checked').val();
        (async() => {
          try {
            Load.loadStart();
            const res = await user.userupdate(param);
            Load.loadEnd();
            if(res) {
              $target.removeClass('edit_active');
              $('.js-user_name').attr('disabled', true);
              $('select[name="year_info"]').attr('disabled', true);
              $('input:radio[name="venue_status"]').attr('disabled', true);
              $target.text('編集');
              $('.js-user_edit_cancel_btn').remove();
              $('.js-title').text('学生情報の編集');
              $('.js-content').text('学生情報の編集が完了しました。');
              openModal('completeJsModal');
            }
          } catch(e) {
            console.log(e);
          };
        })();
      } else {
        $target.addClass('edit_active');
        $('.js-user_name').attr('disabled', false);
        $target.parent().append(`<button type="button" class="btn btn-danger js-user_edit_cancel_btn">キャンセル</button>`)
        $target.text('更新');
      }
    }));

    this.$target.on('click', '.js-user_edit_cancel_btn', ((e) => {
      const $target = $(e.currentTarget);
      $target.prev().text('編集');
      $target.prev().removeClass('edit_active');
      $('.js-user_name').attr('disabled', true);
      $('select[name="year_info"]').attr('disabled', true);
      $('input:radio[name="venue_status"]').attr('disabled', true);
      $target.remove();
    }));

    // 該当する全てのチェックがtrueならdisable解除
    this.$target.on('click', '.js-document_check_flg', ((e) => {
      const target = $(e.currentTarget);
      let arr = [];
      document.querySelectorAll('.js-document_check_flg').forEach((val, key) => {
        arr.push(val.checked);
      });
      if(arr.find(element => element === false) === false){
        document.querySelector('#js-flow_id').disabled = true;
      }else{
        document.querySelector('#js-flow_id').disabled = false;
      }
    }));

    this.$target.on('submit', '.js-task_form', ((e) => {
      document.querySelector('#js-flow_id').disabled = false;
    }));

    // ロード時に値格納、ボタンクリック時他の値であればチェックされているか確認
    window.addEventListener('load', () => {
      if(document.querySelector('#js-flow_id')){
        const flow_id_load_value = document.querySelector('#js-flow_id').value;
        this.$target.on('click', '#js-entry_update', ((e) => {
          const target = $(e.currentTarget);
          let arr = [];
          if(flow_id_load_value !== document.querySelector('#js-flow_id').value){
            if(document.querySelectorAll('.js-document_check_flg')){
              document.querySelectorAll('.js-document_check_flg').forEach((val, key) => {
                arr.push(val.checked);
              });
              if(arr.find(element => element === false) === false){
                document.querySelector('.d-none_text').classList.remove('d-none');
                document.querySelector('#js-entry_update').type = 'button';
              }else{
                document.querySelector('.d-none_text').classList.add('d-none');
                document.querySelector('#js-entry_update').type = 'submit';
              }
            }
          }else{
            if(Number(document.querySelector('input[name="radio"]:checked').value) === 1){
              $('.js-task_task_company_id').val($('.js-task_select option:selected').attr('data-select_val'));
              if($('.js-task_task_company_id').val() !== ''){
                document.querySelector('#js-entry_update').type = 'submit';
              }else{
                document.querySelector('#js-entry_update').type = 'button';
              }
            }else{
              $('.js-task_task_company_id').val(0);
              document.querySelector('#js-entry_update').type = 'submit';
            }
          }
        }));
      }else if(document.querySelector('.js-entry_save')){
        // document.querySelector('#js-entry_update').addEventListener('click', () => {
        //   // Company\EntryController@saveの処理
        //   const form = document.querySelector('.js-entry_save');
        //   const input = form.querySelectorAll('input');
        //   let result = [];
        //   input.forEach((element, key) => {
        //     switch(element.name){
        //       case 'name':
        //       case 'name_kana':
        //       case 'sex':
        //       case 'phone':
        //       case 'email':
        //       case 'via':
        //       case 'school':
        //       case 'undergraduate':
        //       case 'study_type':
        //         if(element.value === ''){
        //           result.push(false);
        //         }
        //         break;
        //     }
        //   })
        //   console.log(result)
        //   if(result.includes(false)){
        //     document.querySelector('#js-entry_update').type = 'button';
        //   }else{
        //     document.querySelector('#js-entry_update').type = 'submit';
        //   }
        // });
      }
    });

    /**
     * [送信した際発火]
     * 
     * ・flowのセレクトボックスを送信する前にdisableを外す
     * 
     * ・タスクのセレクトボックスから選択したオプションからtask_company_id取得してname=task_company_idに挿入
     *
     * @return  {[type]}                 [return description]
     */
    this.$target.on('submit', '.js-task_form', ((e) =>{

      document.querySelector('#js-flow_id').disabled = false;

      document.querySelectorAll('.js-chenge_radio').forEach((v, k) => {
        switch(v.checked){
          case true:
            switch(Number(v.value)){
              case 1:
                // セレクトボックスで選択している番号を取得
                const select_number = document.querySelector('.js-task_select').selectedIndex;

                // セレクトボックスのオプションを取得
                const option = document.querySelector('.js-task_select').options[select_number];

                // task_company_id取得
                const task_company_id = option.getAttribute('data-select_val');

                // name=task_company_idのvalueに値挿入
                document.querySelector('input[name=task_company_id]').value=task_company_id;
                break;
              case 2:
                document.querySelector('.js-task_company_id').value = 0;
                break;
            }
            break;
          case false:
            break;
        }
      });
    }));
  }
}
