import $ from 'jquery';
import LineApi from '../../model/LineApiModel';
import openModal from '../../utils/openModal';

const lineApiModel = new LineApi();

export default class {
  constructor($target) {
    this.$target = $target;
    this.bind();
  }

  bind() {
    $(document).ready((() => {
      let param = {};
      param.id = $('.js-user_id').val();
      (async() => {
        try {
          const res = await lineApiModel.lineshow(param);
          console.log(res);
        } catch(e) {
          console.log(e);
        };
      })();
    }));

    this.$target.on('click', '.js-line_send_button', ((e) => {
      let param = {};
      param.message = $('.js-line_message').val();
      param.user_id = $('.js-user_id').val();
      (async() => {
        try {
          const res = await lineApiModel.linessend(param);
          console.log(res);
        } catch(e) {
          console.log(e);
        };
      })();
    }));

    this.$target.on('click', '.js-line_img', ((e) => {
      const $target = $(e.currentTarget);
      const imagePath = $target.attr('src');
      console.log(imagePath);
      $('.js-modal_image_path').attr('src', imagePath);
      openModal($target.attr('data-modalName'));
    }));
  }
}
