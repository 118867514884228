export default class {
  constructor(target) {
    if (typeof target === 'string') {
      this.$target = Array.from(document.querySelectorAll(target))
    } else {
      this.$target = target
    }

    if (this.$target === null || this.$target.length === 0) {
      this.status = 'notExists'
    } else {
      this.status = 'exists'
    }
    return this
  }

  /**
   * [rejectIfNotFunction 関数でなければrejectする]
   * @param  {Function}  func 判定する対象.
   * @return {void}      特に何も返さない.
   */
  rejectIfNotFunction(func) {
    if (typeof func !== 'function') {
      this.status = 'reject'
      this.rejectReason = 'argument is not a function.'
    }
  }

  /**
   * [then 要素の存在に応じて処理を振り分ける]
   * @param  {Function} existsFunc  存在時に実行する関数.
   * @param  {Function} notExistsFunc 非存在時に実行する関数.
   * @param  {Function} catchFunc エラー時に実行される関数.
   * @return {Object}  自身を返す.
   */
  then(existsFunc, notExistsFunc, catchFunc) {
    if (this.status === 'exists') {
      return this.exists(existsFunc, catchFunc)
    } else if (this.status === 'notExists') {
      return this.notExists(notExistsFunc, catchFunc)
    }
    return this.catch(catchFunc)
  }

  /**
   * [exists 要素が存在する場合に実行される]
   * @param {Function} func 実行する関数.
   * @param {Function} catchFunc エラー時に実行する関数.
   * @return {Object}      自身を返す.
   */
  exists(func, catchFunc) {
    this.rejectIfNotFunction(func)
    if (this.status === 'reject') {
      return this.catch(catchFunc)
    }
    if (this.status !== 'exists') {
      return this
    }
    if (func(this.$target) === false) {
      this.status = 'reject'
      this.rejectReason = 'function return false.'
    }
    return this
  }

  /**
   * [notExists 要素が見つからなかった場合に実行する]
   * @param  {Function} func 実行する関数.
   * @param {Function} catchFunc エラー時に実行する関数.
   * @return {Object}      自身を返す.
   */
  notExists(func, catchFunc) {
    this.rejectIfNotFunction(func)
    if (this.status === 'reject') {
      return this.catch(catchFunc)
    }
    if (this.status !== 'notExists') {
      return this
    }
    if (func() === false) {
      this.status = 'reject'
      this.rejectReason = 'function return false.'
    }
    return this
  }

  /**
   * [catch エラー時に実行される(見つからなかった時ではない)]
   * @param  {Function}   func  実行する関数.
   * @return {Object}  自身を返す.
   */
  ['catch'](func) {
    if (this.status === 'reject' && typeof func === 'function') {
      func({
        reason: this.rejectReason
      })
    }
    return this
  }

  static dom($target, callback, ...args) {
    if ($target === null || $target.length === 0) {
      return false
    }
    if (typeof callback === 'function') {
      callback.call($target, ...args)
    }
    return true
  }
}
