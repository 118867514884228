export default class renderHTML{

  /**
   * [renderHTML HTML要素作成]
   *
   * @param   {[string]}  tag        [tag HTMLのタグ名]
   * @param   {[string]}  content    [content タグの中に入れる文字]
   * @param   {[element]}  location   [location 作成した要素を入れる親要素]
   * @param   {[string]}  className  [className クラス名]
   *
   * @return  {[element]}             [return description]
   * 
   * -- How to use --
   * import renderHTML from '../../utils/renderHTML';
   * renderHTML.renderHTML(argument, argument, argument, argument);
   * 
   */
  static renderHTML(tag, content, location, className){
    let element = document.createElement(`${tag}`);
    element.innerHTML = content;
    if(className){
      element.className = className;
    }
    if(location){
      location.append(element);
    }else{
      return element;
    }
  }

}