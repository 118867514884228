import $ from 'jquery';
import validate from '../../utils/validate';

export default class {
  constructor($target) {
    this.$target = $target;
    this.bind();
  }

  bind() {
    this.$target.on('click', '.js-validate_submit', ((e) => {

      // 要素を配列に格納
      validate.validate(document.querySelectorAll('.js-validate'));
      
    }));
  }
}
