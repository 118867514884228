import $ from 'jquery';
import questionList from '../../utils/questionList'
import renderHTML from '../../utils/renderHTML'

export default class {
  constructor($target) {
    this.$target = $target;
    this.bind();
  }

  bind() {
    /**
     * アンケート種別ボタンイベント
     * @type {[type]}
     */
    this.$target.on('click', '.js-add', ((e) => {
      const $target = $(e.currentTarget);
      if($target.hasClass('open')) {
        $target.removeClass('open');
        $('.js-add_box_group').remove();
      } else {
        $target.addClass('open');
        $('.js-select_boxs').append(
          `
            <div class="js-add_box_group add_box_group">
              <div class="js-add_box add_box form-group" data-val="1">テキスト</div>
              <div class="js-add_box add_box form-group" data-val="2">テキストエリア</div>
              <div class="js-add_box add_box form-group" data-val="3">チェックボックス</div>
              <div class="js-add_box add_box form-group" data-val="4">ラジオボタン</div>
              <div class="js-add_box add_box form-group" data-val="5">セレクトボックス</div>
            </div>
          `
        );
      }
    }));

    /**
     * アンケートボックス追加
     * @type {[type]}
     */
    this.$target.on('click', '.js-add_box', ((e) => {
      const $target = $(e.currentTarget);
      let targetCount = '';
      if($target.parents().siblings('.js-add_question').find('.group_box').length === 0) {
        targetCount = 0;
      } else {
        const last = $target.parents().siblings('.js-add_question').find('.group_box').find('.js-content_id').length;
        targetCount = Number($target.parents().siblings('.js-add_question').find('.group_box').find('.js-content_id').eq(last-1).attr('data-contentId')) + 1;
        if(!targetCount) {
          const lastDom = $target.parents().siblings('.js-add_question').find('.group_box').find('.js-content_id').eq(last-1).attr('name');
          const disassembly = lastDom.split('-');
          const disassembly2 = disassembly[1].split('_');
          targetCount = Number(disassembly2[0]) + 1;
        }
      }
      // console.log($target.parents().siblings('.js-add_question').find('.group_box').length + 1);
      // console.log($target.parents().siblings('.js-add_question').find('div').length);
      switch ($target.attr('data-val')) {
        case '1':
          $('.js-add_question').append(questionList.addText($target.attr('data-val'), targetCount));
          $('.js-add').removeClass('open');
          $('.js-add_box_group').remove();
          break;
        case '2':
          $('.js-add_question').append(questionList.addTextArea($target.attr('data-val'), targetCount));
          $('.js-add').removeClass('open');
          $('.js-add_box_group').remove();
          break;
        case '3':
          $('.js-add_question').append(questionList.addCheck($target.attr('data-val'), targetCount));
          $('.js-add').removeClass('open');
          $('.js-add_box_group').remove();
          break;
        case '4':
          $('.js-add_question').append(questionList.addRadio($target.attr('data-val'), targetCount));
          $('.js-add').removeClass('open');
          $('.js-add_box_group').remove();
          break;
        case '5':
          $('.js-add_question').append(questionList.addSelect($target.attr('data-val'), targetCount));
          $('.js-add').removeClass('open');
          $('.js-add_box_group').remove();
          break;
      }
    }));

    /**
     * 各選択項目を追加
     * @type {[type]}
     */
    this.$target.on('click', '.js-add_child', ((e) => {
      const $target = $(e.currentTarget);
      const parentTarget = $target.prev('.js-add_child_box').find('.js-add_child_content_box');
      const parentName = $target.prev('.js-add_child_box').find('div').find('.js-select_add_box').attr('name');
      switch ($target.val()) {
        case '1':
          parentTarget.append(questionList.addChildCheck(parentName));
          break;
        case '2':
          parentTarget.append(questionList.addChildRadio(parentName));
          break;
        case '3':
          parentTarget.append(questionList.addChildSelect(parentName));
          break;

      }
      const parentTargetLength = $target.prev('.js-add_child_box').find('.js-add_child_content_box').find('.js-select_add_boxs');
      if(parentTargetLength.length > 1) {
        $target.next().prop('disabled', false);
      } else {
        $target.next().prop('disabled', true);
      }
    }));

    /**
     * 各選択項目で追加した要素削除
     * @type {[type]}
     */
    this.$target.on('click', '.js-delete_child', ((e) => {
      const $target = $(e.currentTarget);
      const parentTarget = $target.prev().prev('.js-add_child_box').find('.js-add_child_content_box:last').find('.js-select_add_boxs:last');
      parentTarget.remove();
      const parentTargetLength = $target.prev().prev('.js-add_child_box').find('.js-add_child_content_box:last').find('.js-select_add_boxs');
      if(parentTargetLength.length > 1) {
        $target.prop('disabled', false);
      } else {
        $target.prop('disabled', true);
      }
    }));

    /**
     * 学生側チェックボックス必須項目制御
     * @type {[type]}
     */
    this.$target.on('change', '.js-check_box', ((e) => {
      const $target = $(e.currentTarget);
      const $targetPare = $target.parent().parent().find('.form-check');
      let count = 0;
      $.each($targetPare, ((i, x) => {
        const findClass = $(x).find('.js-check_box').attr('name').split('_');
        if(Number(findClass[findClass.length - 1].replace('?', '')) === 2) {
          if($(x).find('.js-check_box:checked').length === 1) {
            count++;
          }
        }
      }));
      $.each($targetPare, ((i, x) => {
        const findClass = $(x).find('.js-check_box').attr('name').split('_');
        if(Number(findClass[findClass.length - 1].replace('?', '')) === 2) {
          if(count > 0) {
            $(x).find('.js-check_box').prop('required', false)
          } else {
            $(x).find('.js-check_box').prop('required', true);
          }
        }
      }));
    }));

    this.$target.on('click', '.js-user_answer_submit', ((e) => {
      // js-required取得
      let required = document.querySelectorAll('.js-required');
      let check = [];
      let result = [];
      let bool = [];
      let search = [];

      // js-requiredの子要素であるinput取得
      Object.keys(required).forEach(function (key) {
        check.push(required[key].querySelectorAll('input'));
      });

      // 配列内に存在するinputをforeach、チェックボックス分まわったらboolを挿入、終わったら空にする
      Object.keys(check).forEach(function (key) {
        check[key].forEach((v, k) => {
          result.push(v.checked)
        })
        bool.push(result);
        result = [];
      });

      // 配列内に1つでもtrueが存在したら、配列に挿入
      bool.forEach(function (v, k){
        search.push(v.includes(true));
      });

      // 配列内に1つでもfalseがあればbutton、無ければsubmitに変換
      if(search.includes(false)){
        document.querySelector('.js-user_answer_submit').type = 'button';
        if(!document.querySelector('.check_delete')){
          renderHTML.renderHTML('p', '必須であるチェックボックスにチェックを入れてください', document.querySelector('.js-check_error'), 'check_delete')
        }else{
          document.querySelector('.check_delete').remove();
          renderHTML.renderHTML('p', '必須であるチェックボックスにチェックを入れてください', document.querySelector('.js-check_error'), 'check_delete')
        }
        
      }else{
        document.querySelector('.js-user_answer_submit').type = 'submit';
      }
    }));
  }
}
