import $ from 'jquery';
import { request, apiUrl, params } from '../utils';

const baseUrl = 'api/companyhistory';


export default class CompanyhistoryModel {
  companyhistoryindex = async (params) => {
    try {
      const param = $.param(params);
      const res = await request.GET(apiUrl.fetchUrl(`${baseUrl}`, `index?${param}`));

      return res;
    } catch (e) {
      throw new Error(e);
    }
  }

  entryhistorysave = async (params) => {
    try {
      const res = await request.POST(apiUrl.fetchUrl(`${baseUrl}`, 'save'), params);

      return res;
    } catch (e) {
      throw new Error(e);
    }
  }

  companyhistorysort = async (params) => {
    try {
      const param = $.param(params);
      const res = await request.GET(apiUrl.fetchUrl(`${baseUrl}`, `sort?${param}`));

      return res;
    } catch (e) {
      throw new Error(e);
    }
  }

}
