import $, { each } from 'jquery';
import Load from '../../utils/load';
import renderHTML from '../../utils/renderHTML';

export default class {
	constructor($target) {
		this.$target = $target;
		this.bind();
	}

	bind() {
		this.$target.on('click', '.js-search', ((e) => {
			let arr = [];
			document.querySelectorAll('.js-custom_select').forEach((val, key) => {
				arr.push(val.value);
			});
			if(Number(arr.find(element => Number(element) === 0)) === 0){
				if(Number(document.querySelector('.js-manager').value) !== 0){
					document.querySelector('.js-search').type = 'submit';
				}else{
					if(document.querySelector('.js-error_text')){
						document.querySelector('.js-error_text').remove();
					}
					let element = renderHTML.renderHTML('p', '年月日を全て選択してください。');
					element.classList.add('js-error_text');
					document.querySelector('.js-error').append(element);
				}
			}else{
				document.querySelector('.js-search').type = 'submit';
			}
		}));
	}
}
