import $ from 'jquery';
import moment from 'moment';

export default class {
  constructor($target) {
    this.$target = $target;
    this.bind();
  }

  bind() {
    // this.$target.on('change', '.js-start_time_modal', ((e) => {
    //   const $target = $(e.currentTarget);
    //   const $targetTime = $target.find('option:selected').attr('data-val');
    //   const $targetTimeAdd = moment($targetTime).add(1, 'hours').format('HH:mm');
    //   $('.js-end_time_modal').text($targetTimeAdd);
    //   $('.js-calendar_user_event_end_time').val($targetTimeAdd);
    // }));
  }
}
