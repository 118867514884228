/**
 * ロード画面
 * 
 * mainタグの中に入れる
 * 
 * import Load from '../../utils/load';
<div class="" id="js-load_wrap">
  <div class="" id="js-load_box">
    <div class="" id="js-loader"></div>
  </div>
</div>
 */
export default class Load{

  static loadStart(){
    document.getElementById('js-load_wrap').classList.add('load_wrap');
    document.getElementById('js-load_box').classList.add('load_box');
    document.getElementById('js-loader').classList.add('loader');
  }
  
  static loadEnd(){
    document.getElementById('js-load_wrap').classList.remove('load_wrap');
    document.getElementById('js-load_box').classList.remove('load_box');
    document.getElementById('js-loader').classList.remove('loader');
  }

}