import $ from 'jquery';
import imagePreview from '../../utils/imagePreview';
import 'jquery-jpostal-ja';

export default class {
  constructor($target) {
    this.$target = $target;
    this.bind();
  }

  bind() {
    this.$target.on('change', '.js-img_change', ((e) => {
      const $targetBtn = $(e.currentTarget);
      const file = e.target.files[0];
      imagePreview($targetBtn, file);
    }));

    /**
     * 入力フォーム全角＆半角空白削除
     * @type {[type]}
     */
    this.$target.on('blur', '.form-control', ((e) => {
      const $target = $(e.currentTarget);
      const targetVal = $.trim($target.val());
      $target.val(targetVal);
    }));

    /**
     * 郵便番号
     * @type {Array}
     */
    $(document).ready((() => {
      $('.js-company_zip').jpostal({
        postcode: [
          '.js-company_zip'
        ],
        address: {
          '.js-address': '%3%4%5'
        }
      });
    }));
  }
}
