import $ from 'jquery';
import openModal from '../../utils/openModal';

export default class {
  constructor($target) {
    this.$target = $target;
    this.bind();
  }

  bind() {
    /**
     * [conpanyのアンケート表示]
     *
     * @param   {[type]}  .js-openCommonModal  [conpanyのアンケート表示]
     *
     * @return  {[type]}                       [return void]
     */
    this.$target.on('click', '.js-openCommonModal', ((e) => {
      const target = $(e.currentTarget);
      const data_page = target.attr('data-page');
      const data_id = target.attr('data-id');
      const data_val = target.attr('data-val');
      const csrf = $('[name="csrf-token"]').attr('content');
      if(Number(data_val) === 0){
        $('.js-common_modal_title').text('非表示にしますか？');
      }else{
        $('.js-common_modal_title').text('表示しますか？');
      }
      $('.js-common_modal_form').append(`<input name="_token" type="hidden" value="${csrf}" class="js-src">`)
      $('.js-common_modal_form').attr('action', `/company/${data_page}/hide/${data_id}`);
      openModal(target.attr('data-modalName'));
    }));

    /**
     * [companyのentryに表示するタスク]
     *
     * @param   {[type]}  .js-companyEntryTaskModal  [entryのタスク]
     *
     * @return  {[type]}                             [return void]
     */
    this.$target.on('click', '.js-companyEntryTaskModal', ((e) => {
      const target = $(e.currentTarget);
      const title = target.attr('data-title');
      const remark = target.attr('data-remark');
      $('.js-nomal_modal_title').text(title);
      $('.js-nomal_modal_content').text(remark);
      $('.js-nomal_modal_no').text('閉じる');
      $('.js-nomal_modal_yes').css('display', 'none');
      openModal(target.attr('data-modalName'));
    }));

    this.$target.on('click', '.js-companyEntryMailModal', ((e) => {
      const target = $(e.currentTarget);
      let bool_box = [];
      const action = target.attr('data-action'); // モーダルにフォーム追加
      const method = target.attr('data-method'); // フォームのメソッド
      const title = target.attr('data-title');
      const csrf = $('[name="csrf-token"]').attr('content');
      $('.js-nomal_modal_form').attr('method', method);
      $('.js-nomal_modal_form').attr('action', action);
      $('.js-nomal_modal_title').text(title);

      // input追加
      $('.js-nomal_modal_content').append(`<input name="_token" type="hidden" value="${csrf}" class="js-csrf">`)
      if(!$(".form-group").length){
        $('.js-nomal_modal_content').append(
          `
          <div class="form-group">
            <label for="nomal_modal_input_text">※メールタイトル</label>
            <input type="text" name="mail_title" class="js-nomal_modal_form_item form-control" id="nomal_modal_input_text" placeholder="メールのタイトルを入力して下さい" required>
            <div class="js-mail_title-error js-modal_error"></div>
          </div> `
        );
        $('.js-nomal_modal_content').append(
          `
          <div class="form-group">
            <label for="nomal_modal_textarea">※メッセージ</label>
            <textarea name="msg" class="js-nomal_modal_form_item form-control" id="nomal_modal_textarea" rows="3" required placeholder="テキストエリアにメッセージを入力して下さい。"></textarea>
            <div class="js-msg-error js-modal_error"></div>
          </div> `
        );
      }
      
      // モーダル展開
      $('.js-nomal_modal_no').text('閉じる');
      $('.js-nomal_modal_yes').text('送信');
      openModal(target.attr('data-modalName'));

      // validate
      $('.js-nomal_modal_yes').on('click', (e) =>{
        nomalModalValidate();
      });

      // 初期化
      Initialize();
      
    }));

    this.$target.on('click', '.js-companyEntryRejectModal', ((e) => {
      const target = $(e.currentTarget);
      const action = target.attr('data-action'); // モーダルにフォーム追加
      const method = target.attr('data-method'); // フォームのメソッド
      const title = target.attr('data-title');
      const content = target.attr('data-content');
      const csrf = $('[name="csrf-token"]').attr('content');
      $('.js-nomal_modal_form').attr('method', method);
      $('.js-nomal_modal_form').attr('action', action);
      $('.js-nomal_modal_title').text(title);
      $('.js-nomal_modal_content').text(content);
      $('.js-nomal_modal_content').append(`<input name="_token" type="hidden" value="${csrf}" class="js-csrf">`);
      openModal(target.attr('data-modalName'));

      $('.js-nomal_modal_yes').on('click', () => {
        if(!$(".form-group").length){
          $('.js-nomal_modal_content').append(
            `
            <div class="form-group">
              <label for="exampleFormControlSelect2">不採用の都合を選択して下さい</label>
              <select name="not_adopted" multiple class="form-control" id="exampleFormControlSelect2">
                <option value="1">学生都合</option>
                <option value="2">会社都合</option>
              </select>
              <div class="js-not_adopted-error"></div>
            </div>
            `
          );
          $('.js-nomal_modal_content').append(
            `
            <div class="form-group">
              <label for="exampleFormControlTextarea1">不採用理由を記載してください</label>
              <textarea name="entry_adopted_comment" class="form-control" id="exampleFormControlTextarea1" rows="3" placeholde="不採用理由" required></textarea>
              <div class="js-entry_adopted_comment-error"></div>
            </div>
            `
          );
        }else{
          nomalModalValidate();
        }
      });

      // 初期化
      Initialize();

    }));

    /**
     * .form-controlをeachでバリデーション
     *
     * @return  {[type]}  [return void]
     */
    function nomalModalValidate(){
      let bool_box = [];
      if($('.js-nomal_modal_content').find('.form-control').length){
        $('.js-nomal_modal_content').find('.form-control').each((k, v) => {
          switch($(v).attr('name')){
            case 'mail_title':
              bool_box.push(validateCheck(v));
              break;
            case 'msg':
              bool_box.push(validateCheck(v));
              break;
            case 'not_adopted':
              bool_box.push(validateCheck(v));
              break;
            case 'entry_adopted_comment':
              bool_box.push(validateCheck(v));
              break;
          }
        });
        if(bool_box.length === $('.js-nomal_modal_content').find('.form-control').length && !bool_box.includes(false)){
          $('.js-nomal_modal_yes').attr('type', 'submit');
        }else{
          $('.js-nomal_modal_yes').attr('type', 'button');
        }
      }
    }

    /**
     * バリデーションチェック
     *
     * @param   {[type]}  v  [v .form-control]
     *
     * @return  {[type]}     [return void]
     */
    function validateCheck(v){
      switch(true){
        case $(v).val().length === 0:
          const error = '.js-'+$(v).attr('name')+'-error';
          $(error).text('※必須');
          return false;
          break;
        default:
          return true;
      }
    }

    /**
     * 追加したelement削除
     *
     * @return  {[type]}  [return void]
     */
    function Initialize(){
      $('.js-nomal_modal_no').on('click', () =>{
        $('.js-nomal_modal_form').attr('method', '');
        $('.js-nomal_modal_form').attr('action', '');
        $('.js-nomal_modal_title').text('');
        $('.js-nomal_modal_content').empty();
      });
    }

  }
}

// How to use

// mainタグにjs-commonModalを追加

// aタグを下記の様に追加

// <a href="#"
//  class="js-className" jsで操作するクラス名
//  data-modalName="nomalModal" nomalModal.bladeを呼び出すID
//  data-page="entry/index" formのaction
//  >
//</a>

// blade下記にコンポーネント追加
// @component('parts.modal.nomalModal')@endcomponent