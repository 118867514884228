import $ from 'jquery';

export default class {
  static addDataValues(dataId, dataVal) {
    let box = '';
    box = `
      <div class="js-task_values">
        <input type="hidden" name="task_name-${dataId}" value="${dataVal}">
      </div>
    `

    return box;
  }

  static addTaskList(res) {
    let box = '';
    $(res).each(((i, x) => {
      console.log(x);
      box += `
        <div class="js-add_task_list">
          <label>${x.task_company.task_company_name}</label>
          <div>${x.task_company.task_company_remarks}</div>
        </div>
      `;
    }));

    return box;
  }
}
