import $ from 'jquery';

export default class {
    constructor($target) {
        this.$target = $target;
        this.bind();
    }

    bind() {
        $('.navbar-toggler').on('click', e => {
            $( '#navbarToggleExternalContent' ).toggle(  );
        } );
    }
}
