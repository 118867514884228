import $ from 'jquery';
import openModal from '../../utils/openModal';

export default class {
  constructor($target) {
    this.$target = $target;
    this.bind();
  }

  bind() {
    this.$target.on('click', '.js-openRemodal', ((e) => {
      const $target = $(e.currentTarget);
      openModal($target.attr('data-modalName'));
    }));
  }
}
