import $ from 'jquery';
// import openModal from '../../utils/openModal';

export default class {
  constructor($target) {
    this.$target = $target;
    this.bind();
  }

  /**
   * ファイル存在チェックとアップ
   * @param  {[type]} files [description]
   * @return {[type]}       [description]
   */
  handleFiles(files) {
    const file = files[0];
    const fileType = '.csv$';

    // filetypeをnameに変更(ブラウザによって取得できない？)
    if(!file.name.match(fileType)) {
      alert('失敗！！');
      $('#input_file').val('');
      $('#drop_area').css('border', '1px dashed #aaa');
      return;
    }


    $('#drop_area').hide();
    $('#icon_clear_button').show();
    // 追加
    $('#up_sccess').show();

    // let img = document.createElement('img');
    // const reader = new FileReader();
    // reader.onload = function () {
    //   img.src = reader.result;
    //   $('#preview_field').append(img);
    // }
  }

  /**
   * イベント防止
   * @param  {[type]} e [description]
   * @return {[type]}   [description]
   */
  handleDrag(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  bind() {
    /**
     * ブラウザバック禁止
     * @type {[type]}
     */
    history.pushState(null, null, null);
    $(window).on('popstate', function(){
      history.pushState(null, null, null);
    });

    /**
     * クリックを行ったらファイルファインダーを開く
     * @type {[type]}
     */
    this.$target.on('click', '#drop_area', ((e) => {
      const $target = $(e.currentTarget);
      $('#input_file').click();
    }));

    this.$target.on('change', '#input_file', ((e) => {
      const $target = $(e.currentTarget);
      this.handleFiles($target[0].files);
    }));

    /**
     * ドロップ枠にもっていったら
     * @type {[type]}
     */
    this.$target.on('dragenter dragover', '#drop_area', ((e) => {
      const $target = $(e.currentTarget);
      this.handleDrag(e);
      $target.css('border', '1px solid #000');
    }));

    /**
     * ドロップ外れた時
     * @type {[type]}
     */
    this.$target.on('dragleave', '#drop_area', ((e) => {
      const $target = $(e.currentTarget);
      this.handleDrag(e);
      $target.css('border', '1px dashed #aaa');
    }));

    /**
     * 枠にドロップした時
     * @type {[type]}
     */
    this.$target.on('drop', '#drop_area', ((e) => {
      const $target = $(e.currentTarget);
      const fileType = '.csv$';
      e.preventDefault();
      // let dropFile = document.getElementById("input_file").files;
      document.getElementById("input_file").files = e.originalEvent.dataTransfer.files;
      // const dropFile = e.originalEvent.dataTransfer.files;
      if($('#input_file')[0].files.length > 1) {
        $('#input_file').val('');
        return;
      }
      // filetypeをnameに変更(ブラウザによって取得できない？)
      if(!$('#input_file')[0].files[0].name.match(fileType)) {
        alert('失敗！！');
        $('#input_file').val('');
        $('#drop_area').css('border', '1px dashed #aaa');
        return;
      }

      $('#drop_area').hide();
      $('#icon_clear_button').show();
      // 追加
      $('#up_sccess').show();

      // this.handleFiles(dropFile);
    }));

    /**
     * 選択したファイルを削除
     */
    this.$target.on('click', '#icon_clear_button', ((e) => {
      $('#preview_field').empty();
      $('#input_file').val('');
      $('#drop_area').show();
      $('#icon_clear_button').hide();
      // 追加
      $('#up_sccess').hide();
      //
      $('#drop_area').css('border', '1px dashed #aaa');
    }));

    /**
     * エリア以外でファイルを置いた場合の防止
     */
    $(document).on('dragenter', ((e) => {
      this.handleDrag(e);
    }));

    $(document).on('dragover', ((e) => {
      this.handleDrag(e);
    }));

    $(document).on('drop', ((e) => {
      this.handleDrag(e);
    }));
  }
}
